import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Inner from '../../components/templates/Inner';
import { Divider, Box, Flex, Text } from '../../components/atoms';
import FootNote from '../../components/molecules/FootNote';
import img01 from '../../assets/img/design-tips/less/01.png';
import img02 from '../../assets/img/design-tips/less/02.png';
import img03 from '../../assets/img/design-tips/less/03.png';
import img04 from '../../assets/img/design-tips/less/04.png';
import img05 from '../../assets/img/design-tips/less/05.png';
import img06 from '../../assets/img/design-tips/less/06.png';
import img07 from '../../assets/img/design-tips/less/07.png';
import img08 from '../../assets/img/design-tips/less/08.png';
import { designTips as nav } from '../../nav';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Inner nav={nav} title="Less is more" mdxType="Inner">
      <h3>{`Less is more`}</h3>
  <Text maxWidth={560} mdxType="Text">
    Being DRY means Don’t Repeat Yourself. Following this primary principle can keep you safe in the long term.
  </Text>
  <Divider my={64} mdxType="Divider" />
      <h4>{`Stick with 3 colors`}</h4>
  <Text maxWidth={560} mdxType="Text">
    Try to use 3 main colors at max to maintain a perfect balance. Play with shades and tones if you need diversity.
  </Text>
  <Flex my="xxl" mdxType="Flex">
    <Box width={400} mr={152} mdxType="Box">
      <Box mdxType="Box"><img src={img02} alt="" className="img-fluid" /></Box>
      <FootNote status="good" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">3 colors used, much easier to concentrate</Box>
    </Box>
    <Box width={400} mdxType="Box">
      <Box mdxType="Box"><img src={img01} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">4 colors used, hard to concentrate</Box>
    </Box>
  </Flex>
  <Text maxWidth={560} mdxType="Text">
    Follow the 60% / 30% / 10% rule for the best color scheme balance. Use 60% as your primary color, 30% as secondary, supporting the main one, and 10% as the color of attention, used for CTA, etc.
  </Text>
  <Divider my={64} mdxType="Divider" />
      <h4>{`Maintain a hierarchy`}</h4>
  <Text maxWidth={560} mdxType="Text">
    Avoid using too many fonts, their sizes, and inscriptions. Create a consistent visual hierarchy across the whole app.
  </Text>
  <Flex my="xxl" mdxType="Flex">
    <Box width={390} mr={152} mdxType="Box">
      <Box maxWidth={144} height={147} mdxType="Box"><img src={img04} alt="" className="img-fluid" /></Box>
      <FootNote status="good" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Hierarchy + font size matches heading importance.</Box>
    </Box>
    <Box width={390} mdxType="Box">
      <Box maxWidth={136} mdxType="Box"><img src={img03} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">
        1. No visual hierarchy.<br />
        2. Different fonts/inscriptions in use
      </Box>
    </Box>
  </Flex>
      <blockquote>
        <p parentName="blockquote">{`Exception: This advice works for interfaces, commercial and technical projects well. Design magazines and blogs offer sets of headlines/paragraphs with 2-3 various fonts/inscriptions.`}</p>
      </blockquote>
  <Flex my="xxl" mdxType="Flex">
    <Box width={392} mr={152} mdxType="Box">
      <Box height={76} mdxType="Box"><img src={img06} alt="" className="img-fluid" /></Box>
      <FootNote status="good" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Determine a specific font/size and use it throughout the application.</Box>
    </Box>
    <Box width={392} mdxType="Box">
      <Box mdxType="Box"><img src={img05} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Content is inconvenient to read. Font and inscription have changed within the paragraph, it’s confusing.</Box>
    </Box>
  </Flex>
      <blockquote>
        <p parentName="blockquote">{`Exception: You may use bold to highlight `}<strong parentName="p">{`important phrases`}</strong>{`. Don't overdo it, all right?`}</p>
      </blockquote>
  <Divider my={64} mdxType="Divider" />
      <h4>{`Create consistent rhythm`}</h4>
  <Text maxWidth={560} mdxType="Text">
    Create a consistent interface rhythm. Document spacings and use them with the same consistency, from page to page.
  </Text>
  <Box maxWidth={800} mdxType="Box"><img src={img07} alt="" className="img-fluid" /></Box>
  <Divider my={64} mdxType="Divider" />
      <h4>{`Stay DRY`}</h4>
  <Text maxWidth={560} mdxType="Text">
    Multiplication of entities is a common mistake. It is terrible when multiple different components perform the same function. It brings more technical debt, UX and scalability issues.
  </Text>
  <Box maxWidth={840} mdxType="Box"><img src={img08} alt="" className="img-fluid" /></Box>
  <Box width={424} my="xxl" mdxType="Box">
    <FootNote status="good" mdxType="FootNote" />
    <Box fontSize="m" mdxType="Box">Create multi-functional components, change and expand them.</Box>
  </Box>
  <Divider my={64} mdxType="Divider" />
      <h4>{`Know their rules`}</h4>
  <Text maxWidth={560} mdxType="Text">
    Google and Apple got totally different visual guidelines. As well as their user’s habbits and experience. It is imperative to consider its philosophy, principles, navigation, appearance, etc.
  </Text>
      <p>{`  `}<strong parentName="p">{`Apple Human Interface Guidelines`}</strong></p>
      <p>{`  `}<a parentName="p" {...{
          "href": "https://developer.apple.com/design/human-interface-guidelines/"
        }}>{`https://developer.apple.com/design/human-interface-guidelines/`}</a></p>
      <p>{`  `}<strong parentName="p">{`Google Material Design`}</strong></p>
      <p>{`  `}<a parentName="p" {...{
          "href": "https://material.io/design/"
        }}>{`https://material.io/design/`}</a></p>
    </Inner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      